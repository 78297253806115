
import { CollectionObject } from '../firebase/firebase-user';
import { getImageUrl } from '../firebase/firebase-verify';
import './ImageGallery.css';
import {useState,useEffect} from 'react';

export function ImageGallery({data,size}){




    useEffect(()=>{

    })
    function setImages() {
        return data.map((e,i)=>{
            return <Image key={'image' + e.data.id} id={e.data.id}  />
        })
    }

    return(
        <div className="img-gallery" >
            {data && setImages()}
        </div>
    )
}

function Image({ id, caption}) {
    const [loaded, setLoad] = useState(false)

   
    function start() {
        setLoad(true)

    }    
    function getClass(k){

        const u = (k)?'slide-left':' '
        return 'img-case '+u
    }

    return (
        <div className={`img-painting ${(loaded)?'':'hidden'}`}>
        <div>

            <div className="case-border" >
                <div className="case-inner" >
                  {/* <div className={getClass()} alt="image" style={{backgroundImage:'url("'+src+'")'}}/>*/}
                    <img src={getImageUrl(id,"large")} className={getClass()} onLoad={start} alt="image" style={{maxHeight:'400px'}} />
                    
                </div>
            </div>
        </div>
    </div>

    )
}

/*
    const types = Object.keys(idObject)
    const ids = idObject[types[0]]
  //  const { data, fields } = CollectionObject({ folder: 'images/entries/'+types, filter: ['id', 'in', ids] })
    const [data,setData]=useState(null)

    useEffect(()=>{
        (async()=>{
        const folder = `images/entries/`+types;
        const filter = ['id', 'in', ids];
       const d = await CollectionObject({ folder, filter })
        setData(d)
        })()
    },[])
    */