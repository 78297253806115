import { useEffect, useState, useRef } from 'react';
import './App.css';
import { HomePage } from './pages/Home';
import { ProjectPage } from './pages/Projects';
import { About } from './pages/Contact';
import { Nav } from './nav/Nav';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BrowserRouter as Router, Route, Routes, Switch, useLocation, Navigate } from 'react-router-dom';
import { PageLine } from './Resources/elements/pageline';
import { CollectionObject, DocumentObject } from './Resources/firebase/firebase-user';


function Website({ data }) {
    const [current, setCurrent] = useState('home')
    const [state, setState] = useState('loaded')

    const location = useLocation()

    function change(str) {
        setCurrent(str)
        setState('loading')
    }

    useEffect(() => {
        change(location.pathname.substring(1));
    }, [location]);

    return (
        <div className="App">
            <div className="background" />
            <div className='app-content'>
                <Nav pages={['Home', 'Theater', 'Beeldende Kunst', "About"]} />
                <section className="sect-mid">
                    <Routes>
                        <Route path="/" element={
                            <Navigate to="/home" replace />
                        } />
                        <Route path="/home" element={
                            <HomePage contact={data.contact} front={data.front}
                            />
                        }
                            className="route-fade"
                        />
                        <Route path="/theater" element={
                            <ProjectPage title={'Theater'} data={data.theater} />
                        }
                            className="route-fade"
                        />
                        <Route path="/beeldende-kunst"
                            element={
                                <ProjectPage title="Beeldende Kunst" data={data.art} />
                            }
                            className="route-fade"
                        />
                        <Route path="/about" element={
                            <About contact={data.contact} data={data.about} />
                        }
                            className="route-fade"
                        />
                    </Routes>
                    <div style={{ width: '100%' }}>
                        <PageLine />
                        <div style={{ textAlign: 'center', paddingBottom: '10px' }}>© Lisanne Bovee</div>
                    </div>
                </section>
            </div>

        </div>
    );
}

export default function App() {
    const [data, setData] = useState(null)

    const contactData = DocumentObject({ folder: 'forms', type: 'LhhZITNRzkVMOey2tQuY' })
    const frontData = DocumentObject({ folder: 'forms', type: 'ffSti367e4hDrepUSKfJ' })
    const aboutData = DocumentObject({ folder: 'forms', type: 'BPLDQKyw8frDvoAVbF6h' })
    const artData = CollectionObject({ folder: `projects/entries/SnMtuLGgWQrlf07Disu5` })
    const theaterData = CollectionObject({ folder: 'projects/entries/Ik6CTcOxtrLOyJC1yzWo' })
    const imageData = CollectionObject({ folder: `images/entries/ylG2BLfStQCJTEA2ScrU` })
    const check = {};


    useEffect(() => {
        if (contactData && frontData && artData && theaterData && imageData && aboutData && !data) {

            setImages(frontData)
            setImages(artData)
            setImages(theaterData)
            setImages(aboutData)

            const end = {
                contact: contactData,
                front: frontData,
                about: aboutData,
                art: artData,
                theater: theaterData,
                images: imageData,
            }

            setData(end)
        }

        function setImages(dat) {
            if (Array.isArray(dat.data)) {
                dat.data.forEach(d => {
                    set(d)
                })
                return
            }
            set(dat)
            function set(d) {
                d.fields.forEach(f => {
                    if (f.type === 'UploadImages' || f.type === "Image") {
                        if (d.data[f.id] === null) {
                            return
                        }
                        const obj = Object.values(d.data[f.id]);
                        const imgIds = (f.type === "UploadImages") ? obj[0] : obj;

                        const imgData = imgIds.map(i => {
                            const img = imageData.data.find(d => {
                                return (d.data.id === i)
                            })
                            if (!img) {
                                const k = d.get('Titel');
                                if (!check[k]) {
                                    check[k] = []
                                }
                                check[k].push(i)

                                console.error('Image missing in image directory', i)

                                return false
                            }
                            return img
                        })
                        d.data[f.id] = imgData;
                    }
                })
            }

        }

    }, [contactData, frontData, artData, theaterData, imageData])
    return (<div>{data &&
        <Router>
            <Website data={data} />
        </Router>
    }</div>)

}
