import './Projects.css';
import { useState, useEffect, useRef, createRef, Children } from 'react';
import { ImageSlider } from '../Resources/elements/ImageSlider'
import { CO, CollectionObject } from '../Resources/firebase/firebase-user'
import { PageLine } from '../Resources/elements/pageline'
import { Popup } from '../Resources/elements/Popup'
import { createPortal } from 'react-dom';





export function Page({ title, children }) {
    return (
        <div className="page">
            {title &&
                <div>
                    <h2 className="title">{title}</h2>
                    <PageLine />
                </div>
            }
            <div className="container">
                {children}
            </div>

        </div>
    )
}


export function ProjectPage({ title, data }) {

    const ref = createRef()
    const active = useState(true)

    function setChildren() {
        const dd = data.data.sort((a, b) => {
            if (!isNaN(a.data.index) && !isNaN(b.data.index)) {
                return a.data.index - b.data.index
            }
            return 1
        })

        let ii = -1;

        return dd.map((d, i) => {

            return (<ProjectCard active={active} data={d} key={'project-' + i} />)
        })
    }


    return (
        <Page title={title}>
            <div ref={ref} className="project-container">
                {data && setChildren()}
            </div>
        </Page>
    )
}



function ProjectCard({ data, fields,style,active }) {
    const elemRef = useRef();
    const isVisible = useIsVisible(elemRef);
    const [state, setState] = useState(null)
    const [load, setLoad] = useState(false)
    
    useEffect(()=>{
        active[1]((!state));
    },[state])



    function removeQuotes(d) {
        return d.replaceAll("‘", '').replaceAll("’", '')
    }


    return (
        <div style={style} className={`project-outer ${((load) ? 'loaded' : 'hidden')}`} >
            <a href="" className="project" ref={elemRef} onClick={(e) => { setState(true);e.preventDefault() }}>
                <div className="front">
                    <div className="content">
                        {isVisible && (
                            <div>
                                <ImageSlider load={setLoad} shadow={false} auto={active[0]} type="fade" size="medium" fit="cover" id={data.data.id} key={"img" + data.data.id} data={data.get('Fotos')} />
                            </div>
                        )}
                        <div className='card-text'>
                            <div className='card-title'>
                                <h3 className="c-title">{data.get('Titel')}</h3>

                                <div className="year"> <span style={{ margin: '0px 2px' }}>|</span>{data.get('Jaar')}</div>
                            </div>
                            <div className='card-mention'>
                                {data.get('Producent')}
                            </div>
                        </div>

                        {state && createPortal(
                            <Popup title={data.get('Titel')} size="flex" Background={Background} content={(
                                <div className="project-expand">
                                    <div>
                                        <div className="grid"><div>
                                            <ImageSlider shadow={true} type="slide" size="large" fit="contain"  id={data.id} key={"img" + data.id} data={data.get('Fotos')} control={true} captions={true} list={true} />
                                            <PageLine padding='8px' width="85%" size='1px' />
                                        </div>
                                            <div className="details">
                                                <div className="main">
                                                    <div className="producent">{data.get('Producent')}</div>
                                                    <div className="year">{data.get('Jaar')}</div>
                                                </div>
                                                <div className="meta">
                                                    <div className="grid2">
                                                        <div className="format"><strong>Formaat:</strong> </div><p>{data.get('Formaat')}</p>
                                                        <div className="material"><strong>Materiaal:</strong></div><p>{data.get('Materiaal').join(', ')}</p>

                                                    </div>
                                                </div>

                                                <div className="credits">
                                                    {data.get('Groep').map((g, i) => {
                                                        return (
                                                            <div key={i}>
                                                                <div></div>
                                                                <div>
                                                                    <strong>{g.label}: </strong>
                                                                    {g.value.map((p, i) => {

                                                                        const end = (i === g.value.length - 1) ? '.' : ',';
                                                                        return (<span key={i} style={{ fontStyle: 'italic' }}>{p}{end} </span>)
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div>

                                                <div className="description">{data.get('Beschrijving')}</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            )} onClose={() => { setState(false) }} />,
                            document.body
                        )}
                        {
/*
createPortal(
                <Popup title={get('Titel')} content={(
                    <div className='project-expand'>
                    <div className='content'>
                        <span style={{ margin: '0px 2px' }}></span>
                        <div className="year">{get('Jaar')}</div>
                        <div className='card-mention'>
                        {get('Producent')}
                        </div>
                        <div style={{maxWidth:'720px',margin:'auto'}}>
                        <ImageSlider key={"ímg"+data.id} data={get('Fotos')} control={true}  />
                        </div>
                        <div className='description'>{get('Beschrijving')}</div>
                        <div>{get('Auteur')}</div>
                        <div>{getGroup()}</div>
                    </div>
                    <Background/>

                </div>
                )} onClose={()=>{setState(false)}}/>,
                document.body
                )*/}
                    </div>
                </div>
                <Background />      
            </a>
        </div>
    )
}


export function Background() {
    const outer = {
        backgroundColor: 'rgb(138 138 138 / 13%)',
        position: 'absolute',
        /* box-shadow: 'rgb(0 0 0 / 24%) 0px 3px 8px', */
        zIndex: '-10',
        height: '100%',
        width: '100%',
        top: '0px',
    }
    const inner = {
        background: 'url(`../../public/images/lacer.png`)',
        backgroundSize: '50%',
        height: '100%',
        width: '100%',
        filter: 'brightness(50%) sepia(100) saturate(1) hue-rotate(190deg)',
        opacity: '0.080',
    }

    return (
        <div style={outer}>
            <div style={inner} />
        </div>)
}

function useIsVisible(elementRef) {
    const [isVisible, setIsVisible] = useState(false);
    const OPTIONS = {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 0,
    };
    useEffect(() => {
        if (elementRef.current) {
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(elementRef.current);
                    }
                });
                if (isVisible) {

                }
            }, OPTIONS);
            observer.observe(elementRef.current);
        }
    }, [elementRef]);
    return isVisible;
};


/*
            <div className="project">
                <div className="front">
                    {isVisible && (
                    <div className="content">
                        {expanded && (
                        <div className="info">
                            <div className="description info-style">{data.tekst}</div>
                            <div className="info-style">
                                <div className="format"><span className="header">Formaat: </span>{data.formaat}</div>
                                <div className="material"><span className="header">Materiaal: </span>{data.materiaal.join(', ')}</div>
                            </div>
                            {m && (m)}
                        </div>)}
                        <div className="info2">
                            <ImageSlider expand={expanded} folder={data.folderName} caption={data.fotograaf} icon="camera" imageLoc={data.fotos} />
                            <div className="title">{data.naam}</div>
                            <div className="year">{data.jaar}</div>
                        </div>
                    </div>)}
                </div>
                <div className="back">
                    <div className="back-image" />
                </div>
            </div>

            //////////////////////////////////////////////////////////////




            
const ProjectIndex = forwardRef(
    function ProjectIndex({ data, current }, ref) {
        const indd = current[0] / 100
        const ind = Math.floor(indd * data.length)
        const k = document.querySelector('.project-container')
        const [state, setState] = useState(false)
        function pointerDown(e) {
            const d = e.target.className;
            if (d === 'scroll-slider' || d === 'line') {
                const pr = e.currentTarget.getClientRects()[0];
                const h = e.clientY - pr.y;
                const hr = h / pr.height;
                console.log(hr);
            }
            if (d === 'dot') { }
            setState(true);
        }
        function pointerMove(e) {
            if (state) {
            }
        }
        function pointerUp() {
            setState(false)
        }
        function setline(d) {
            console.log('line')
            console.log(d)
        }
        const title = data.map((d, i) => {
            let state = ''
            if (i === ind) {
                state = 'active'
            }
            function scroll() {
                console.log(k, data.length, i, k.scrollHeight)
                const y = (i / (data.length + 1)) * k.scrollHeight
                console.log('scroll to ' + y)
                console.log(current)
                k.scrollTo({
                    top: y,
                    left: 100,
                    behavior: 'smooth'
                })
            }
            return (<div><div onClick={scroll} key={'title' + i + d.folderName} className={"title-nav " + state}>{d.naam}</div></div>)
        })
        return (<div ref={ref} className="project-index">
            <div className="background"></div>
            <div className="scroll-slider" onPointerUp={pointerUp} onPointerDown={pointerDown} onPointerMove={pointerMove}>
                <div className="line"  >
                    <div style={{ top: current[0] + '%' }} className="dot"></div>
                </div>
            </div>
            <div className="container">{title}</div>
        </div>)
    })


            */