import './SocialIcons.css';
import { FaFacebookF, FaInstagram, FaPhone, FaWhatsapp, FaRegCopy, FaMapMarkedAlt, FaRegEnvelope, FaArrowRotateRight, FaCheck } from 'react-icons/fa';
import { MdEmail, MdOutgoingMail, MdOutlinePhoneIphone } from 'react-icons/md';
import { IconContext } from 'react-icons/lib';
import { DocumentObject } from '../firebase/firebase-user';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { isMobile } from 'react-device-detect';
export function SocialIcons({ data }) {

    return (
        <IconContext.Provider value={{ size: "25px", className: "icon-image" }}>
            {data && data.fields &&
                <div className='social-container'>
                    <InstagramIcon username={data.get('Instagram')} />
                    <EmailIcon adress={data.get('Email adres')} />
                    <PhoneIcon number={data.get('Telefoon nummer')} />
                    <WhatsappIcon number={data.get('Telefoon nummer').replace('+', '')} />
                </div>}
        </IconContext.Provider>
    )
}
//            {data.get('Facebook') !== null && <a target="_blank" href={data.get('Facebook')}><FaFacebookF /></a>}
/*
 */

function CopyText({ text, className }) {
    const [state, setState] = useState(false)

    async function copy(e) {
        setState('load')

        const text = e.target.innerText
        try {
            await navigator.clipboard.writeText(text);
            setState('copied')
            setTimeout(() => { setState(false) }, 1200)
        } catch (err) {
            setState(false)
        }
    }


    return (
        <button aria-label='Copy text' onClick={copy} className={'copy-text' + ' ' + state} >
            <div className={'text '+ className}>{text}</div>
            <div className='copy-icon'>
                {!state && <FaRegCopy />}
                {state === 'load' && <FaRegCopy />}
                {state === 'copied' && <FaCheck />}
            </div>
        </button>)
}


function EmailIcon({ adress }) {
    return ((
        <Icon href={"mailto:" + adress} IconType={MdOutgoingMail} />
    ))
}
function PhoneIcon({ number }) {
    return ((
        <Icon href={"tel:" + number} IconType={FaPhone} />
    ))
}
function WhatsappIcon({ number }) {
    return ((
        <Icon href={"https://api.whatsapp.com/send?phone=" + number} IconType={FaWhatsapp} />
    ))
}
function InstagramIcon({ username }) {
    const [url, setUrl] = useState(null);

    useEffect(() => {
        if (isMobile) {
            setUrl('instagram://user?username=' + username)
            return
        }
        setUrl('https://www.instagram.com/' + username)
    }, [username])
    return ((
        <Icon href={url} IconType={FaInstagram} />
    ))
}

function Icon({ href, click, IconType, alternate }) {

    function getClass() {
        const str = (alternate) ? 'alternate' : ''
        return "icon " + str
    }

    if (href) {
        return <a className={getClass()} role="button" target="_blank" href={href}><IconType /></a>
    }
    if (click) {
        return <button className={getClass()} onClick={click}><IconType /></button>
    }
    return (<div></div>)
}


export function ContactData({ data }) {
    const email = useMemo(() => { return data.get('Email adres') }, [])
    const phone = useMemo(() => { return data.get('Telefoon nummer') }, [])

    return (
        <IconContext.Provider value={{ size: "25px", className: "icon-image" }}>
            <div className='contact-container'>
                <ul>
                    {email && <li>
                        <div>
                            <div><FaRegEnvelope />
                                <CopyText text={email} className={'contact-name'} />
                            </div>
                            <EmailIcon adress={email} />
                        </div>
                    </li>}
                    {phone && <li>
                        <div>  
                            <div>
                            <MdOutlinePhoneIphone />
                            <CopyText text={phone} className={'contact-name'} />    </div>
                            <div>
                                <PhoneIcon number={phone} />
                                <WhatsappIcon number={phone.replace('+', '')} />
                            </div>
                        </div>
                    </li>}
                </ul></div>

        </IconContext.Provider>
    )
}

