// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, setDoc, query, getDoc, updateDoc, writeBatch, doc, where, deleteDoc, addDoc, getDocs, QuerySnapshot, serverTimestamp } from 'firebase/firestore';
import { getDatabase, ref as dataRef, set } from 'firebase/database'
import { firebaseDatabase } from "./firebase-verify";
import { useState, useEffect, useMemo } from 'react';




export const Database = {
    async getCollection(path, filter /*where("capital", "==", true)*/) {

        const p = 'data/' + path
        const wher = (filter) ? where(...filter) : undefined
        const q = (wher) ?
            query(collection(firebaseDatabase, p), wher) :
            collection(firebaseDatabase, p)
        const querySnapshot = await getDocs(q);
        const data = await transpose(querySnapshot)
        return data

        async function transpose(k) {
            const obj = []
            k.forEach(e => {
                obj.push(e.data())
            })
            return obj
        }
    },

    async getDoc(path, id) {
        const p = 'data/' + path;
        const docs = doc(firebaseDatabase, p, id)
        const querySnapshot = await getDoc(docs);
        const d = querySnapshot.data()
        return d
    }
}
/////////////////////////////////////////////////////////////////////////////////////////
/*

export function CollectionObject({ folder,  filter,defaultFields }) {
    const [fields, setFields] = useState()
    const [data, setData] = useState(null)
    useEffect(() => {
      //  const path = (type)?folder + '/entries/' + type:folder;
      const path = folder;
        
        (async () => {
            console.log('COLLECTION DOWNLOAD')
            const f = await Database.getCollection(path + '/fields')
            const d = (filter &&filter[2].length === 0)?[]:await Database.getCollection(path + '/entries', filter);
            setData(d);
            (defaultFields)?setFields([...defaultFields,...f]):setFields(f)
        })()
    }, [folder])

    return { data, fields }
}

*/

export function CollectionObject({ folder, filter, defaultFields }) {

    const [data, setData] = useState(null)
    useEffect(() => {
        //  const path = (type)?folder + '/entries/' + type:folder;
        const path = folder;
        (async () => {
            const f = await Database.getCollection(path + '/fields')
            const data = (filter && filter[2].length === 0) ? [] : await Database.getCollection(path + '/entries', filter);
            const fields = (defaultFields) ? [...defaultFields, ...f] : f
            setData(new Data(fields, data.map(d => { return new Data(fields, d) })))
        })()
    }, [folder])

    return data
}

export function DocumentObject({ folder, type, defaultFields }) {

    const [data, setData] = useState(null)

    useEffect(() => {
        const path = folder + '/entries';
        const p = path + '/' + type + '/fields';
        (async () => {
            try {
                const f = await Database.getCollection(p);
                const data = await Database.getDoc(path, type);
                const fields = (defaultFields) ? [...defaultFields, ...f] : f
                setData(new Data(fields, data));

            } catch (error) {
                console.error(error);
            }
        })()
    }, [])

    return data
}

///Data Object
class Data extends Object {
    constructor(f, d) {
        super()
        this.fields = f
        this.data = d
    }
    get(str, i) {
        if (!this.fields || !this.data) { return }
        const f = this.fields.find(f => f.name === str)
        if (!f) { return []}
        if (Array.isArray(this.data)) {
            if (!i && i !== 0) { return }
            if (this.data[i].data[f.id]) {
                return this.data[i].data[f.id]
            }
        }
        if (this.data[f.id]) {
            return this.data[f.id]
        }
        return []
    }
}