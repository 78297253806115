import './Nav.css';
import { useEffect, useState, useRef } from 'react';
import { Link ,useLocation} from 'react-router-dom';

export function Nav({ pages }) {
   // const [index, setIndex] = useState(0)
    const [expanded, setExpand] = useState(true);
    const [dimensions, setDimensions] = useState(false)
    const [top, _setTop] = useState(0)
    const [left, setLeft] = useState('7.4%')
    const [isMobile, setMobile] = useState(false)
    const [start, setStart] = useState(false)
    const {state:index}  = useLocation();
    const ref = useRef({})

    function setLine() {
        if (!dimensions) { return }
        if (window.innerWidth > 860) {
            // const r = 12.5 + (25 * index)

            const navWidth = dimensions.nav.width
            const titleWidth = dimensions.title.width

            const rr = (titleWidth / navWidth) * 100;
            const z = (100 - rr) / pages.length
            const zz = z / 2
            const r = rr + zz + (index * z)
            setLeft(r + '%')
            setTop(0)
            return
        }
        setTop(index+1)
        setLeft('7.4%')
    }
    function setTop(ii) {
        const h = dimensions.title.height;
        const h2 = dimensions.nav.height;
        const r = (ii === 0) ? h * 0.886 : h + (h2 / (pages.length + 1.5)) + ((h2 / pages.length) * (ii - 1))
        _setTop(r + 'px')

    }


    ///refresh
    useEffect(() => {

    }, [dimensions])

    function refresh() {
        const mob = (window.innerWidth <= 860)
        setMobile(mob)
        if (mob) {
            setExpand(true)
        }

        const title = ref.current.children[0]
        const navHeight = pages.length * 58

        setDimensions({
            title: {
                width: title.clientWidth,
                height: title.clientHeight
            },
            nav: {
                width: ref.current.clientWidth,
                height: navHeight
            }
        })
        //setMobile
    }

    ///effects
    useEffect(() => {
        if (dimensions && !start && isMobile) {
            setStart(true)
            setTimeout(close, 500)
        }
    }, [dimensions])
    useEffect(setLine, [index, dimensions])

    useEffect(() => {
        window.addEventListener('resize', refresh)
        setTimeout(refresh, 200)
        return () => {
            window.removeEventListener('resize', refresh);
        };

    }, [])



    //////////////////////////////////////////////////////////////////////////////
    function toggle() {
        if (expanded) { close() } else { open() }
    }
    function close() {
        setExpand(false)
        setTop(0)

    }
    function open() {
        setExpand(true)
        setTop(index)

    }
    function getClass() {
        const navclass = (expanded) ? 'open' : 'closed';
        return 'nav-expanded ' + navclass
    }

    const role = isMobile ? "button" : "heading";
    const tabIndexValue = isMobile ? 0 : -1;
    return (
        <section className='nav-section' role="navigation" >
            <nav className={getClass()} ref={ref} style={{ cursor: (isMobile) ? 'pointer' : 'initial' }}>
                <div className="nav-head">
                    <h1 className="nav-title" >
                        <div role={role} tabIndex={tabIndexValue} onClick={(isMobile) ? toggle : () => { }}>Lisanne Bovee</div>
                    </h1>
                </div>
                <ul className="nav-container">
                    {pages.map((p, i) => {
                        const format = p.split(' ').join('-').toLowerCase().trim()
                        return (
                            <li key={format}>
                                <Link
                                    to={`/${format}`}
                                    className="active"
                                    state={i}>
                                    {p}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
            <div className="nav-line" style={{ "top": top }}>
                <div aria-expanded={(expanded)} tabIndex={tabIndexValue} role={isMobile ? "button" : undefined} style={{ cursor: (isMobile) ? 'pointer' : 'initial' }} onClick={(isMobile) ? toggle : () => { }}>
                    <div className="nav-point-container" style={{ "left": left }}>
                        <div className="nav-point">
                            <div /><div /><div /><div />
                        </div>
                        {isMobile && <div className={(expanded) ? 'arrow-icon open' : 'arrow-icon close'}>
                            <div />
                            <div />
                        </div>
                        }
                    </div>
                </div>
            </div>

            <div className="spacer" />
        </section>
    )
}
