
import { FaCamera } from 'react-icons/fa';
import { CollectionObject } from '../firebase/firebase-user';
import './ImageSlider.css';
import { useEffect, useState, useRef, useMemo } from 'react';
import { getImageUrl } from '../firebase/firebase-verify';
////Images

export function ImageSlider({ shadow, id,type = 'fade', size="medium", fit = 'cover', data, border, control, auto, captions, load, list }) {
    const [index, setIndex] = useState(0)
    const [nIndex, setNIndex] = useState([false])
    const [images, setImages] = useState([])
    const time = (type === 'fade') ? 3000 : 800;//(control) ? 8000 : 4000
    const ref = useRef()

    //create image elements
    useEffect(() => {
        const l = data.length;
        const elements = []
        const preloadImages = []
        for (let i = 0; i < l; i++) {
            const img = data[i];
            const caption = (captions) ? img.get('fotograaf') : false
            const src = getImageUrl(img.data.id,size)
      
            elements.push({ id: img.data.id, caption, src });

            const imgElement =document.createElement('img');
            imgElement.src =src
            preloadImages.push(imgElement);
        }
        setImages(elements)
    }, [data])

    useEffect(() => {
        setTimeout(() => {
            if (nIndex[0] !== false) {
                const k = nIndex[0];
                setIndex(k);
                setNIndex([false])
            }
        }, time)
    }, [nIndex])

    useEffect(function () {
        if (auto) {
            setTimeout(next, time * 2);
        }
    })


    if (images.length === 0) {
        return (<div></div>)
    }
    function getImages() {
        if (images.length === 0) {
            return
        }

        const el = images[index];
        const elements = [<Image load={load} shadow={shadow} key={el.id} size={fit} src={el.src} caption={el.caption} />]
        if (nIndex[0] === false) {
            return elements
        }
        const el2 = images[nIndex[0]];
        elements.unshift(<Image shadow={shadow} key={el2.id} size={fit} src={el2.src} caption={el2.caption} />)

        return elements
    }

    ///Switching Images
    /////////////////////////

    function next() {
        if (images.length > 0) {
            const nI = (index + 1) % images.length;
            progress(nI, 'right')
        }
    }
    function previous() {
        if (images.length > 0) {
            const nI = (index - 1 < 0) ? images.length - 1 : index - 1;
            progress(nI, 'left')
        }
    }
    function setDot(i) {
        if (images.length > 0 &&i!==index) {
            const nI = i;
            const dir = (i<index)?'left':'right'
            progress(nI, dir)
        }
    }
    function progress(nI, dir) {
        if (nIndex[0] === false) {
            setNIndex([nI, dir])
        }
    }
    return (

        <div className={`image-slider ${type} ${fit} ${(control)?'padded':''}`}  >
            <div className="content" ref={ref} >
                <div className={(() => {
                    let str = ''
                    if (nIndex[0] !== false) {
                        const s = nIndex[1] || ''
                        str = type + ' ' + s
                    }
                    return 'images ' + str
                })()
                }>

                    {images && getImages()}

                </div>

            </div>
            {control &&
                <div className="control">
                    <div className='left' onClick={next} />
                    <div className='right' onClick={previous} />
                </div>
            }
            <div className='image-dot'>
                {list && images && images.map((e, i) => {

                    const ti = (nIndex[0] === false) ? index : nIndex[0]
                    return (<div key={'dot'+i} onClick={(e)=>{setDot(i,e)}} className={(i === ti) ? 'active' : ''}><div/></div>)
                })}
            </div>
        </div>
    )
}

function Image({ src, caption, size = 'cover', shadow, load }) {

    const [loaded, setLoad] = useState(false)
    const ref = useRef(null);
    const [type, setType] = useState(size);

    useEffect(() => {
        if (ref.current && size === 'contain' && loaded) {
            const imgRef = ref.current.firstChild.firstChild
            const ar = ref.current.clientWidth / ref.current.clientHeight;
            const ari = imgRef.naturalWidth / imgRef.naturalHeight;
            if (ar < ari ||ref.current.clientHeight>imgRef.naturalHeight ) {
                setType('cover');
            }
        }
    }, [ref, loaded]);

    function start() {
        setLoad(true)
        if (load) { load(true) }
    }
    function getClass() {
        const s = (loaded) ? 'loaded' : 'hidden';
        const str = "image " + s
        return str
    }


    return (
        <div className={getClass()} ref={ref} >

            <div className={`image-case ${type}`} style={(shadow) ? { boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' } : {}}>
                <img src={src} onLoad={start} alt="image" />

            </div>
            {caption && caption.length > 0 && (<div className="caption">
                {<FaCamera />}
                {caption}
            </div>)}

        </div>
    )
}