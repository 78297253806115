
import './Home.css';
import {ImageGallery} from '../Resources/elements/ImageGallery'
import {SocialIcons} from '../Resources/elements/SocialIcons'
import { DocumentObject } from '../Resources/firebase/firebase-user';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { PageLine } from '../Resources/elements/pageline';
import { Page } from './Projects';
export function HomePage({front,contact}){
    return(
        <Page>
            {front && contact &&(
                <div>
            <div>
         
                <div className="container">
                    <div className='text-overlay'>
                        <div className="title">Lisanne Bovee</div>
                        <div className="subtitle">{front.get('Ondertitel')}</div>
                    </div>
                    <SocialIcons data={contact} />

                </div>
              
                <PageLine  size="1px" />
            </div>
            <ImageGallery size="dynamic" data={front.get("Foto's voorkant")}/>

            <div className="mentions">

            </div>
            </div>)}
        </Page>
    )
}
