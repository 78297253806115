import { ContactData } from "../Resources/elements/SocialIcons";
import { PageLine } from "../Resources/elements/pageline";
import { Page } from "./Projects";
import './Contact.css';
export function About({contact,data}) {
    return (
<Page title="About">
    <div className="aboutpage">
    <ContactData data={contact}/>
        <div className="contact-image">
        <img style={{width:'100%',clipPath: 'polygon(30% 0%, 100% 25%, 85% 100%, 0% 90%, 10% 5%)'}} src={data.get('Foto')[0].data.urls.large}/>
        </div>
      

    </div>

       
</Page>
    )
}
