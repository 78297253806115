import './Popup.css';
import React, { useState, useRef, useEffect, createContext } from 'react';
import { Button, CloseIcon } from './Buttons';
import { PageLine } from './pageline';


export function Popup({ title, size = 'medium', onClose, content, style, className = '', Background }) {
    const [open, setOpen] = useState('')
    const transition = 0.3

    const popupMenuRef = useRef(null);
    useEffect(() => {
        setOpen('open')
    }, [])

    const popupStyle = {
        transition: 'transform ' + transition + 's ease-in-out',
        ...style
    }
    return (
        <div className={"popup-container " + open} ref={popupMenuRef} style={{ transition: 'background-color ' + transition + 's' }}>

            <div className={"popup " + size + ' ' + className} style={popupStyle}>
                <div>
                    <div className="popup-bar" >
                        <div className="popup-title title">
                            {title}
                        </div>
                        <CloseIcon style={'close-icon'} click={() => { setOpen(''); setTimeout(() => { if (onClose) { onClose() } }, transition * 1000) }} />

                    </div>
                    <PageLine padding="16px" width="90%" />
                </div>

                <div className="popup-content">


                    {content}
                </div>
                {Background && <Background />}
            </div>

        </div>

    );
};

/*
 {onClose &&<div className="btn-standard">
                    <Button text="Close" click={onClose} />

                </div>}
                */